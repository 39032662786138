import { CardTable } from 'components/datatable/Table'
import ConfirmModal from 'components/modal/ConfirmModal'
import ApplicationConf from 'conf/ApplicationConf'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import ServiceAction from 'service/actions/ServiceAction'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useFetch from 'utils/customHook/useFetch'
import useTitles from 'utils/customHook/useTitles'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { isUndefined } from 'lodash'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import { Fieldset } from 'components/form/Fieldset'
import { Dialog, DialogContent, Grid } from '@mui/material'
import Checkbox from 'components/form/Checkbox'
import Input from 'components/form/Input'
import PropTypes from 'prop-types'
import { NEW } from 'components/action/ActionConstant'
import DtoService from 'service/dto/DtoService'

const formatResult = list => list.map(s => new DtoService(s))

const PopinService = ({
    isOpen = false,
    close = () => { },
    reload = () => { },
    service = {},
}) => {
    const dispatch = useDispatch()

    const {
        value: isLoading,
        setTrue: loading,
        setFalse: notLoading,
    } = useBoolean(false)

    const {
        value: isChainCreate,
        toggle: toggleChainCreate,
    } = useBoolean(false)

    const [name, setName] = useState()
    const [apiName, setApiName] = useState()
    const [gitlabName, setGitlabName] = useState()
    const [refs, setRefs] = useState()
    const [instanceType, setInstanceType] = useState()

    useEffect(() => {
        if (isOpen) {
            setName(service.name)
            setApiName(service.apiName)
            setGitlabName(service.gitlabName)
            setRefs(service.refs)
            setInstanceType(service.instanceType)
        }
    }, [service.name, service.apiName, service.gitlabName, service.isDefault, isOpen, service.refs, service.instanceType])

    const resetAllField = () => {
        setName()
        setApiName()
        setGitlabName()
        setRefs()
        setIsDefault(false)
        setInstanceType()
    }

    const dispatchPromise = () => {
        if (isUndefined(service.id)) {
            return dispatch(ServiceAction.createService({ id: -1, name, apiName, gitlabName, instanceType }))
        }
        return dispatch(ServiceAction.updateService({ id: service.id, name, apiName, gitlabName, instanceType }))
    }

    const validate = () => {
        loading()
        dispatchPromise()
            .then(res => {
                if (!res) {
                    return
                }
                reload()
                if (!isChainCreate) {
                    close()
                } else {
                    resetAllField()
                }
            })
            .finally(notLoading)
    }

    return (
        <Dialog
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: 'unset',
                    maxHeight: 'unset',
                },
            }}
        >
            <BasicDialogTitle close={close}>
                Service
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Fieldset>
                    <Grid container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Input
                                label={'Nom'}
                                value={name}
                                onChange={setName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Nom de l\'api'}
                                value={apiName}
                                onChange={setApiName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Nom sur gitlab'}
                                value={gitlabName}
                                onChange={setGitlabName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Références (séparé par un ;)'}
                                value={refs}
                                onChange={setRefs}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Type instance'}
                                value={instanceType}
                                onChange={setInstanceType}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isUndefined(service.id) ? 'Créer' : 'Modifier'}
                validate={validate}
                isLoading={isLoading}
            >
                {
                    isUndefined(service.id) && (
                        <Checkbox
                            label='Saisir un autre service'
                            checked={isChainCreate}
                            onToggle={toggleChainCreate}
                            labelStyle={{ color: '#9e9e9e', fontWeight: 'normal' }}
                        />
                    )
                }

            </BasicDialogAction>
        </Dialog>
    )
}

PopinService.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    reload: PropTypes.func,
    service: PropTypes.shape({
        name: PropTypes.string,
        apiName: PropTypes.string,
        gitlabName: PropTypes.string,
        isDefault: PropTypes.bool,
    }),
}

const ServicesApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        value: isOpenPopin,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: openConfirm,
        setFalse: closeConfirm,
    } = useBoolean(false)

    useTitles(() => [{
        label: 'Service',
        href: '/service',
    }], [])

    const [selectedService, setSelectedService] = useState()

    const {
        data: services = [],
        reload,
    } = useFetch(ApplicationConf.service.all(), undefined, { formatResult })

    const formattedServices = services.map(service => ({
        id: service.id,
        name: { value: service.name },
        apiName: { value: service.apiName },
        gitlabName: { value: service.gitlabName },
        refs: { value: service.refs },
        instanceType: { value: service.instanceType },
    }))

    useActions(() => [{
        type: NEW,
        onClick: openPopin,
    }], [])

    const service = useMemo(() => {
        return services.find(i => i.id === selectedService) ?? {}
    }, [services, selectedService])

    return (
        <div style={{ margin: '10', paddingBottom: '50px' }}>
            <CardTable
                title={'Services'}

                rows={formattedServices}
                headers={['name', 'apiName', 'gitlabName', 'refs', 'instanceType']}
                headersLabel={{
                    name: 'Name',
                    apiName: 'Nom de l\'api',
                    gitlabName: 'Nom sur gitlab',
                    refs: 'Références',
                    instanceType: 'Type instance',
                }}

                actions={[{
                    icon: 'replay',
                    onClick: reload,
                    tooltip: 'Recharger',
                }]}

                onDelete={row => {
                    setSelectedService(row.id)
                    openConfirm()
                }}
                onEdit={row => {
                    setSelectedService(row.id)
                    openPopin()
                }}
            />
            <PopinService
                isOpen={isOpenPopin}
                close={() => {
                    setSelectedService()
                    closePopin()
                }}
                reload={reload}
                service={service}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title='Êtes-vous sûr de vouloir supprimer ce service ?'
                onValidate={() => {
                    dispatch(ServiceAction.deleteService(selectedService)).finally(() => {
                        reload()
                        setSelectedService()
                        closeConfirm()
                    })
                }}
                onClose={closeConfirm}
            />
        </div>
    )
}

export default ServicesApp