export default class DtoService {
    constructor(obj) {
        this.id = obj.id // Int,
        this.name = obj.name // String,
        this.apiName = obj.apiName // String,
        this.gitlabName = obj.gitlabName // String,
        this.refs = obj.refs // String,
        this.isDefault = obj.isDefault // Option[Boolean] = None
        this.instanceType = obj.instanceType
    }
}