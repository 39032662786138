import React from 'react'
import { createRoot } from 'react-dom/client'
import { Link, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import AppStore, { history } from 'store/AppStore'
import envFile from '.env'
import { ThemeProvider } from '@mui/material/styles'
import { Route } from 'react-router-dom'
import BoundaryError from 'components/BoundaryError'
import ToastrContainer from 'toastr/components/ToastrContainer'
import AppTheme from 'AppTheme'
import Wait from 'wait/components/Wait'
import './index.css'
import 'material-icons/iconfont/material-icons.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import PostSlackRedirectApp from './home/components/PostSlackRedirectApp'
import LoginApp from './home/components/LoginApp'
import AquaparcApp from './home/components/AquaparcApp'
import { redirectURI } from './utils/FormUtils'
import DiscordRedirectApp from './home/components/DiscordRedirectApp'


process.envs = {}

fetch(envFile)
    .then(response => response.text())
    .then(text => {
        text.split('\n')
            .filter((env) => env && env.length && env.includes('='))
            .forEach((val) => {
                process.envs[val.split('=')[0]] = val.split('=')[1]
            })
    }).catch(() => {})

if (window.location.href.includes('access_token')) {
    const matches = [...window.location.href.matchAll('access_token=([a-z0-9A-Z\\.]*)')]
    if (matches.length && matches[0].length && matches[0][1]) {
        if (window.location.href.includes('localhost:3002')) {
            redirectURI(`http://localhost:3002/#/discordRedirect?code=${matches[0][1]}`)
        } else {
            redirectURI(`https://aquaparc.aquasys.fr/#/discordRedirect?code=${matches[0][1]}`)
        }
    }
}

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
    <ThemeProvider theme={AppTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={AppStore}>
                <ReduxRouter history={history}>
                    <BoundaryError>
                        <ToastrContainer />
                        <Wait />
                        <Routes>
                            <Route path='/slackRedirect' element={<PostSlackRedirectApp />} />
                            <Route path='/discordRedirect' element={<DiscordRedirectApp />} />
                            <Route path='/login' element={<LoginApp />} />
                            <Route path='*' element={<AquaparcApp />} />
                            {/*<AuthenticatedRoute render={(props) => <AquaparcApp {...props} />} />*/}
                            {/*<Route path='/' element={<SupervisionApp />}>*/}
                            {/*    <Route index element={<DashboardApp />} />*/}
                            {/*    <Route path='service' element={<ServicesApp />} />*/}
                            {/*    <Route path='instance' element={<InstancesApp />} />*/}
                            {/*    <Route path='timeError' element={<TimeErrorsApp />} />*/}
                            {/*    <Route path='*' element={<NoMatch />} />*/}
                            {/*</Route>*/}
                        </Routes>
                    </BoundaryError>
                </ReduxRouter>
            </Provider>
        </LocalizationProvider>
    </ThemeProvider>,
)