import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Paper, TableContainer, TableHead, TableRow, Table as MuiTable, TableCell, TableBody, Icon, typographyClasses, Grid, Card, CardHeader, CardContent, Tooltip } from '@mui/material'
import { floor, isUndefined, orderBy } from 'lodash'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { TablePagintation } from 'components/datatable/Pagination'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { darkBlue } from 'AppTheme'
import { ASC, DESC } from './TableConstant'

const Table = ({
    rows = [],
    headers = [],
    headersLabel = {},

    onClickRow,
    onDelete,
    onEdit,

    defaultSort = { sortColumn: [], sortDirection: {} },

    WrapperComponent = Paper,
}) => {
    const [sort, setSort] = useState(defaultSort)

    const sortedRows = useMemo(() => {
        const {
            sortColumn,
            sortDirection,
        } = sort
        return orderBy(rows, sortColumn.map(c => elem => elem[c]?.sortValue ?? elem[c]?.value), sortColumn.map(c => sortDirection[c]))
    }, [rows, sort])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const filteredRows = useMemo(() => {
        return sortedRows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        )
    }, [sortedRows, page, rowsPerPage])

    useEffect(() => {
        if (page > 0 && rows.length <= page * rowsPerPage) {
            setPage(floor(rows.length / rowsPerPage))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows.length])

    return (
        <WrapperComponent>
            <TableContainer>
                <MuiTable size='small' aria-label='table'>
                    <TableHead>
                        <TableRow>
                            {!isUndefined(onEdit) && <TableCell variant='headerIcon' />}
                            {!isUndefined(onDelete) && <TableCell variant='headerIcon'/>}
                            {
                                headers.map((header, i) => (
                                    <TableCell
                                        variant='head'
                                        sx={{
                                            paddingRight: sort.sortDirection[header] ? '16' : '32',
                                            // textWrap: 'wrap',
                                        }}
                                        key={i}
                                        onClick={() => {
                                            setSort(({ sortDirection, sortColumn }) => {
                                                switch (sortDirection[header]) {
                                                    case ASC: return { sortDirection: { ...sortDirection, [header]: DESC }, sortColumn }
                                                    case DESC: return { sortDirection: { ...sortDirection, [header]: undefined }, sortColumn: sortColumn.filter(c => c !== header) }
                                                    default: return { sortDirection: { ...sortDirection, [header]: ASC }, sortColumn: [...sortColumn, header] }
                                                }
                                            })
                                        }}
                                    >
                                        <Grid container alignItems='center'>
                                            {sort?.sortColumn?.includes(header) && <span style={{ fontSize: 12 }}>{sort.sortColumn.indexOf(header) + 1}</span>}
                                            {
                                                sort.sortDirection[header] === ASC && <Grid item><ArrowUpwardIcon fontSize='14px' /></Grid>
                                            }
                                            {
                                                sort.sortDirection[header] === DESC && <Grid item><ArrowDownwardIcon fontSize='14px' /></Grid>
                                            }
                                            {headersLabel[header] ?? header}
                                        </Grid>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredRows.map((row, ir) => {
                                const funcOnClickRow = onClickRow && (() => onClickRow(row))
                                return (
                                    <TableRow key={ir}>
                                        {
                                            !isUndefined(onEdit) && (
                                                <TableCell
                                                    variant='bodyIcon'
                                                    align={'center'}
                                                    onClick={() => onEdit(row)}
                                                >
                                                    <EditIcon />
                                                </TableCell>
                                            )
                                        }
                                        {
                                            !isUndefined(onDelete) && (
                                                <TableCell
                                                    variant='bodyIcon'
                                                    align={'center'}
                                                    onClick={() => onDelete(row)}
                                                >
                                                    <DeleteIcon />
                                                </TableCell>
                                            )
                                        }
                                        {
                                            headers.map((header, i) => {
                                                const {
                                                    value = '',
                                                    tooltip,
                                                    align = 'left',
                                                    color = 'white',
                                                    fontColor = 'black', // getTextColorByHexColor(color)
                                                    onClick: onClickCell = funcOnClickRow,

                                                    rightIcon = {},
                                                    leftIcon = {},
                                                } = row[header] ?? {}

                                                const {
                                                    icon: leftIconName,
                                                    color: leftIconColor = 'grey',
                                                    onClick: leftIconClick,
                                                } = leftIcon

                                                const {
                                                    icon: rightIconName,
                                                    color: rightIconColor = 'grey',
                                                    onClick: rightIconClick,
                                                } = rightIcon

                                                const tableCell = (
                                                    <TableCell
                                                        key={i}
                                                        variant='body'
                                                        sx={{
                                                            backgroundColor: color,
                                                            cursor: isUndefined(onClickCell) ? 'default' : 'pointer',
                                                            // textWrap: 'wrap',
                                                        }}
                                                        align={align}
                                                        onClick={onClickCell}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center', color: fontColor }}>
                                                            {
                                                                !isUndefined(leftIconName) && (
                                                                    <Icon sx={{ color: leftIconColor, marginRight: '5px', cursor: 'pointer' }} onClick={leftIconClick ? e => {
                                                                        e.stopPropagation()
                                                                        leftIconClick()
                                                                    } : undefined }>{leftIconName}</Icon>
                                                                )
                                                            }
                                                            {value}
                                                            {
                                                                !isUndefined(rightIconName) && (
                                                                    <Icon sx={{ color: rightIconColor, marginLeft: '5px', cursor: 'pointer' }} onClick={rightIconClick ? e => {
                                                                        e.stopPropagation()
                                                                        rightIconClick()
                                                                    } : undefined }>{rightIconName}</Icon>
                                                                )
                                                            }
                                                        </div>
                                                    </TableCell>
                                                )
                                                if (tooltip) {
                                                    return (
                                                        <Tooltip key={i} title={tooltip}>
                                                            {tableCell}
                                                        </Tooltip>
                                                    )
                                                }
                                                return tableCell
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
            <TablePagintation
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                nbRow={rows.length}
            />
        </WrapperComponent>
    )
}

Table.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    // rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //     onClick: PropTypes.func,
    //     color: PropTypes.string,
    //     tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    //     align: PropTypes.oneOf(['left', 'right']),
    //     leftIcon: PropTypes.shape({
    //         icon: PropTypes.string,
    //         color: PropTypes.string,
    //     }),
    //     rightIcon: PropTypes.shape({
    //         icon: PropTypes.string,
    //         color: PropTypes.string,
    //     }),
    // }))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    headersLabel: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),

    onClickRow: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,

    defaultSort: PropTypes.shape({
        sortColumn: PropTypes.arrayOf(PropTypes.string), // headers
        sortDirection: PropTypes.objectOf(PropTypes.string),
    }),

    WrapperComponent: PropTypes.elementType,
}

const TableTitle = ({
    title = '',
    actions = [],
    hideNbElements = false,
    rows = [],
}) => {
    const formattedTitle = useMemo(() => {
        if (hideNbElements || typeof title === 'object') {
            return title
        }
        const nbElements = rows.length
        return `${title} (${nbElements} ${nbElements > 1 ? 'éléments' : 'élément'})`
    }, [hideNbElements, rows.length, title])

    return (
        <Grid container spacing={2} alignItems='center' justifyContent='flex-end' sx={{ paddingRight: '10px' }}>
            <Grid container item xs>
                {formattedTitle}
            </Grid>
            {
                actions.map((action, i) => (
                    <Tooltip key={i} title={action.tooltip}>
                        <Grid item xs='auto' sx={{ cursor: 'pointer' }}>
                            <Icon onClick={action.onClick} sx={{ fontSize: '26px' }}>{action.icon}</Icon>
                        </Grid>
                    </Tooltip>
                ))
            }
        </Grid>
    )
}

TableTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        onClick: PropTypes.func,
        tootip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })),
    hideNbElements: PropTypes.bool,
    rows: PropTypes.array,
}

const CardTable = ({
    title = '',
    actions = [],
    hideNbElements = false,

    rows = [],
    headers = [],
    headersLabel = {},

    onClickRow,
    onDelete,
    onEdit,

    defaultSort,
}) => {
    return (
        <Card>
            <CardHeader
                title={<TableTitle title={title} actions={actions} hideNbElements={hideNbElements} rows={rows} />}
                sx={{
                    backgroundColor: darkBlue,
                    paddingLeft: '20px',
                }}
                titleTypographyProps={{
                    sx: {
                        [`&.${typographyClasses.root}`]: {
                            color: 'white',
                            fontWeight: 'bold',
                        },
                    },
                    variant: 'h6',
                }}
            />
            <Table
                rows={rows}
                headers={headers}
                headersLabel={headersLabel}

                onClickRow={onClickRow}
                onDelete={onDelete}
                onEdit={onEdit}

                defaultSort={defaultSort}

                WrapperComponent={CardContent}
            />
        </Card>
    )
}

CardTable.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        onClick: PropTypes.func,
        tootip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })),
    hideNbElements: PropTypes.bool,

    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    // rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //     onClick: PropTypes.func,
    //     color: PropTypes.string,
    //     tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    //     align: PropTypes.oneOf(['left', 'right']),
    //     leftIcon: PropTypes.shape({
    //         icon: PropTypes.string,
    //         color: PropTypes.string,
    //     }),
    //     rightIcon: PropTypes.shape({
    //         icon: PropTypes.string,
    //         color: PropTypes.string,
    //     }),
    // }))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    headersLabel: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),

    onClickRow: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,

    defaultSort: PropTypes.shape({
        sortColumn: PropTypes.arrayOf(PropTypes.string), // headers
        sortDirection: PropTypes.objectOf(PropTypes.string),
    }),
}

export {
    Table,
    CardTable,
}