import { catchFunctionBuilder, checkAuth, genericPromise2, thenFunctionUpdateBuilder } from '../../utils/ActionUtils'
import ApplicationConf from '../../conf/ApplicationConf'
import DtoUser from '../dto/DtoUser'
import { AdminActionConstant } from '../reducers/AdminReducer'

const AuthAction = {
    logout: () => dispatch => genericPromise2(ApplicationConf.auth.logout())
        // .then((list = []) => dispatch(InstanceActionConstant.instances(list)))
        .catch(catchFunctionBuilder('Erreur lors du logout', dispatch)),
    loginWithSlack: (code, cb) => dispatch => genericPromise2(ApplicationConf.auth.loginWithSlack(code))
        .then(json => cb(json))
        .catch(catchFunctionBuilder('Erreur lors du login with slack', dispatch)),
    loginWithDiscord: (code, cb) => dispatch => genericPromise2(ApplicationConf.auth.loginWithDiscord(code))
        .then(json => cb(json))
        .catch(catchFunctionBuilder('Erreur lors du login with discord', dispatch)),
    promiseUser: login => genericPromise2(ApplicationConf.auth.getUser(login)),
    fetchUser: login => dispatch => AuthAction.promiseUser(login)
        .then(json => dispatch(AdminActionConstant.setUser(new DtoUser(json))))
        .catch(catchFunctionBuilder('Erreur lors du fetch user', dispatch)),
    fetchUsers: () => dispatch => genericPromise2(ApplicationConf.auth.user())
        .then(json => dispatch(AdminActionConstant.setUsers(json.map(j => new DtoUser(j)))))
        .catch(catchFunctionBuilder('Erreur lors du fetch users', dispatch)),
    updateUserHabs: (login, habs) => dispatch => genericPromise2(ApplicationConf.auth.user(), { method: 'PUT', body: { login, habs } })
        .then(thenFunctionUpdateBuilder('User modifié avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors du update user habs', dispatch)),
}

export default AuthAction