import DtoInstance from 'instance/dto/DtoInstance'
import DtoService from 'service/dto/DtoService'

export default class DtoKwaKeUpResult {
    constructor(obj) {
        this.instance = new DtoInstance(obj.instance) // Instance,
        this.service = new DtoService(obj.service) // Service,
        this.port = obj.port // Int,
        this.isRunning = obj.isRunning // Boolean,
        this.deployDate = obj.deployDate // Option[String] = None,
        this.launchDate = obj.launchDate // Option[String] = None,
        this.lastCommit = obj.lastCommit // Option[String] = None,
        this.commitDate = obj.commitDate // Option[String] = None,
        this.memory = obj.memory // Option[String] = None,
        this.number = obj.number // Option[String] = None,
        this.branch = obj.branch // Option[String] = None
        this.directory = obj.directory // Option[String],
        this.totalMemory = obj.totalMemory // Option[Long],
        this.inUseMemory = obj.inUseMemory // Option[Long],
        this.maxMemory = obj.maxMemory // Option[Long],
        this.nbProcess = obj.nbProcess // Option[Int]
    }
}