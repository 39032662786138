import React from 'react'
import { uniq, countBy, isNil, round } from 'lodash'
import Select from '../../components/form/Select'
import { Button, Grid, IconButton, SvgIcon } from '@mui/material'
import { CardTable } from 'components/datatable/Table'
import DtoKwaKeUpResult from '../dto/DtoKwaKeUpResult'
import PropTypes from 'prop-types'
import { greyBlue } from 'AppTheme'
import Input from 'components/form/Input'
import { DESC } from '../../components/datatable/TableConstant'
import {getFullDate} from "../../utils/DateUtil";

const EqualIcon = () => (
    <SvgIcon style={{ fontSize: '26px', color: '#666666' }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path fill='currentColor' d='M18 9.998H6a1 1 0 1 1 0-2h12a1 1 0 0 1 0 2zm0 6H6a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z' />
        </svg>
    </SvgIcon>
)

const NotEqualIcon = () => (
    <SvgIcon style={{ fontSize: '26px', color: '#666666' }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path fill='currentColor' d='M18 9.998H6a1 1 0 1 1 0-2h12a1 1 0 0 1 0 2zm0 6H6a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z' />
            <path fill='currentColor' d='M14.999 5H15c.507.219.742.806.525 1.314l-5.212 12.162A.999.999 0 0 1 9 19a1.002 1.002 0 0 1-.525-1.314l5.212-12.162A.999.999 0 0 1 15 5z' />
        </svg>
    </SvgIcon>
)

const KwakeUpTable = ({
    results = [],
    filter = {},
    setFilter = () => { },
}) => {
    const {
        datacenter,
        branch,
        instance,
        service,
        version,
        versionIsEqual,
    } = filter

    const filteredByInstance = instance ? results.filter(r => r.instance.name === instance) : results
    const filteredByServer = datacenter ? filteredByInstance.filter(r => r.instance.server === datacenter) : filteredByInstance
    const filteredByBranch = branch ? filteredByServer.filter(r => r.branch === branch) : filteredByServer
    const filteredByService = service ? filteredByBranch.filter(r => r.service.name === service) : filteredByBranch
    const filteredByVersion = version ? filteredByService.filter(r => versionIsEqual ? r.number === version : r.number !== version) : filteredByService

    const listIcon = {
        icon: 'filter_list',
        color: '#35609f',
    }

    const tableData = filteredByVersion.map(r => {
        return {
            instance: {
                value: r.instance.name,
                color: r.isRunning ? '#85ffab' : '#ff8e8e',
                leftIcon: {
                    icon: 'filter_list',
                    color: '#35609f',
                    onClick: () => setFilter(f => ({ ...f, instance: r.instance.name })),
                },
                rightIcon: {
                    icon: r.instance.url ? 'send' : undefined,
                    color: '#35609f',
                    onClick: r.instance.url ? () => window.open(r.instance.url, '_blank') : undefined,
                },
            },
            service: {
                value: r.service.name,
                color: r.isRunning ? '#85ffab' : '#ff8e8e',
                leftIcon: listIcon,
                onClick: () => setFilter(f => ({ ...f, service: r.service.name })),
            },
            directory: {
                value: r.directory,
                color: !r.directory ? '#fd7d26' : undefined
            },
            server: {
                value: r.instance.server ? `dt${r.instance.server}` : undefined,
                leftIcon: listIcon,
                onClick: () => setFilter(f => ({ ...f, datacenter: r.instance.server })),
            },
            version: {
                value: r.number,
                leftIcon: listIcon,
                onClick: () => setFilter(f => ({ ...f, version: r.number, versionIsEqual: true })),
            },
            commit: {
                value: `${r.commitDate || ''} ${r.lastCommit ? `[${r.lastCommit.substring(0, 10)}]` : '[???]'}`,
                rightIcon: {
                    icon: r.lastCommit ? 'send' : undefined,
                    color: '#35609f',
                },
                onClick: r.lastCommit ? () => window.open(`https://gitlab2.aquasys.fr/sieau/${r.service.gitlabName}/-/commit/${r.lastCommit}`, '_blank') : undefined,
                color: !r.lastCommit || !r.commitDate ? '#fd7d26' : undefined,
            },
            branch: {
                value: r.branch,
                color: !r.branch ? '#fd7d26' : undefined,
                leftIcon: r.branch ? listIcon : undefined,
                onClick: () => setFilter(f => ({ ...f, branch: r.branch })),
            },
            deploy: { value: getFullDate(r.deployDate), color: !r.deployDate ? '#fd7d26' : undefined },
            launch: { value: getFullDate(r.launchDate), color: !r.launchDate ? '#fd7d26' : undefined },
            inUseMemory: {
                value: r.inUseMemory ? `${round(r.inUseMemory / (1024*1024))} (${round(r.inUseMemory * 100 / r.maxMemory)}%)` : undefined,
                sortValue: r.inUseMemory ? round(r.inUseMemory * 100 / r.maxMemory) : undefined,
                color: r.inUseMemory && round(r.inUseMemory * 100 / r.maxMemory) >= 90 ? '#ff8e8e' : undefined
            },
            totalMemory: { value: r.totalMemory ? round(r.totalMemory / (1024*1024)) : undefined },
            maxMemory: { value: r.maxMemory ? round(r.maxMemory / (1024*1024)) : undefined },
            nbProcess: { value: r.nbProcess },
            isProblem: !r.isRunning,
            isIncomplete: [r.lastCommit, r.commitDate, r.branch, r.deployDate, r.launchDate, r.memory].some(e => isNil(e)),
        }
    })
    const nbProblems = countBy(tableData, 'isProblem')?.true || 0
    const nbIncomplete = countBy(tableData, 'isIncomplete')?.true || 0
    const instances = uniq(results.map(r => r.instance.name))
    const services = uniq(results.map(r => r.service.name))
    const servers = uniq(results.map(r => r.instance.server))
    const branches = uniq(results.map(r => r.branch ?? 'inconnue'))

    return (
        <>
            <Grid container spacing={2} alignItems='center' style={{ paddingBottom: '5px' }}>
                <Grid item xs={2}>
                    <Select
                        label='Instance'
                        items={instances.map(i => ({ value: i, label: i }))}
                        value={instance}
                        onChange={v => setFilter(f => ({ ...f, instance: v }))}
                        undefinedValue
                    />
                </Grid>
                <Grid item xs={2}>
                    <Select
                        label='Service'
                        items={services.map(i => ({ value: i, label: i }))}
                        value={service}
                        onChange={v => setFilter(f => ({ ...f, service: v }))}
                        undefinedValue
                    />
                </Grid>
                <Grid item xs={2}>
                    <Select
                        label='Serveur'
                        items={servers.map(i => ({ value: i, label: i }))}
                        value={datacenter}
                        onChange={v => setFilter(f => ({ ...f, datacenter: v }))}
                        undefinedValue
                    />
                </Grid>
                <Grid item xs={2}>
                    <Select
                        label='Branche'
                        items={branches.map(i => ({ value: i, label: i }))}
                        value={branch}
                        onChange={v => setFilter(f => ({ ...f, branch: v }))}
                        undefinedValue
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        value={version}
                        onChange={v => setFilter(f => ({ ...f, version: v }))}
                        label='Version'
                        endAdornment={
                            <IconButton
                                onClick={() => setFilter(f => ({ ...f, versionIsEqual: !f.versionIsEqual }))}
                                edge='end'
                            >
                                {versionIsEqual ? <EqualIcon /> : <NotEqualIcon />}
                            </IconButton>
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={() => {
                            setFilter({})
                        }}
                    >
                        Annuler
                    </Button>
                </Grid>
            </Grid>
            <CardTable
                title={(<div>{filteredByService.length} MS, {instances.length} instances, {servers.length} serveurs, <span style={{ color: 'red' }}>{nbProblems} Problèmes</span>, <span style={{ color: 'orange' }}>{nbIncomplete} Incomplets</span></div>)}

                rows={tableData}
                headers={['instance', 'service', 'directory', 'server', 'version', 'commit', 'branch', 'deploy', 'launch', 'inUseMemory', 'totalMemory', 'maxMemory', 'nbProcess']}
                headersLabel={{
                    instance: 'Instance',
                    service: 'Service',
                    url: 'URL',
                    server: 'Serveur',
                    version: 'Version',
                    commit: 'Commit',
                    branch: 'Branche',
                    deploy: 'Deploy',
                    launch: 'Launch',
                    directory: 'Répertoire',
                    inUseMemory: 'RAM utilisée',
                    totalMemory: 'Allouée',
                    maxMemory: 'Max',
                }}
                defaultSort={ { sortColumn: ['inUseMemory'], sortDirection: { inUseMemory: DESC } } }
            />
        </>
    )
}

KwakeUpTable.propTypes = {
    results: PropTypes.arrayOf(PropTypes.instanceOf(DtoKwaKeUpResult)),
    filter: PropTypes.shape({
        datacenter: PropTypes.number,
        branch: PropTypes.string,
        instance: PropTypes.string,
        service: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

export default KwakeUpTable